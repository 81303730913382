import { Typography, Form, AutoComplete, Input, Space, Button, InputNumber, Select } from 'antd';
import Password from 'antd/es/input/Password';
import { regionsService } from 'services/regions.service';
import { iJobModel, iRegionModel } from 'shared/deployment';
import { buttonBorder } from 'utils/styles';
import { awsRegionNames } from '../settings/TerraformJobsConfig';
import gql from 'graphql-tag';
import { authService } from 'services/auth.service';
import { useState } from 'react';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { DEFAULT_CLUSTER_VERSION, k8sVersionsList } from 'shared/k8sVersions';

const { Title } = Typography;
const titles = { fontWeight: 'bold', marginTop: '10px' };
const texts = { marginTop: '10px', marginBottom: '10px' };

/**
 * Show it in case cluster was not created.
 * We got an error and do not shure about the reason.
 * May be it is wrong aws keys may be wrong cluster configuration.
 *
 * So will render AWS keys for with cluster configuration together.
 * @param props
 * @returns
 */
export const ChangeAWSkeysAndRunJob = (props: { region: iRegionModel; onSave?: any; editJob: iJobModel }) => {
  const region = props.region;
  const [inLoading, setInLoading] = useState(false);

  let natValue = `single_nat_gateway`;
  if (props.editJob?.variables?.use_nat === false) {
    natValue = `use_nat_false`;
  } else if (props.editJob?.variables?.use_nat === true) {
    if (props.editJob?.variables?.single_nat_gateway) {
      natValue = `single_nat_gateway`;
    } else if (props.editJob?.variables?.one_nat_gateway_per_az) {
      natValue = `one_nat_gateway_per_az`;
    } else {
      natValue = `use_nat_false`;
    }
  }

  const createOrUpdateJob = async (values: any) => {
    try {
      setInLoading(true);
      let nat_type = values.nat_type;
      delete values.nat_type;

      // Docs:
      // https://registry.terraform.io/modules/terraform-aws-modules/vpc/aws/latest#nat-gateway-scenarios
      if (nat_type === `use_nat_false`) {
        values.use_nat = false;
        values.single_nat_gateway = false;
        values.one_nat_gateway_per_az = false;
      } else if (nat_type === `single_nat_gateway`) {
        values.use_nat = true;
        values.single_nat_gateway = true;
        values.one_nat_gateway_per_az = false;
      } else if (nat_type === `one_nat_gateway_per_az`) {
        values.use_nat = true;
        values.single_nat_gateway = false;
        values.one_nat_gateway_per_az = true;
      }

      await regionsService.updateOwnRegion(region.id, {
        regionName: values.regionName,
        TF_AWS_ACCESS_KEY_ID: values.TF_AWS_ACCESS_KEY_ID,
        TF_AWS_SECRET_ACCESS_KEY: values.TF_AWS_SECRET_ACCESS_KEY,
      });

      delete values.TF_AWS_ACCESS_KEY_ID;
      delete values.TF_AWS_SECRET_ACCESS_KEY;

      let editJob = props.editJob;
      if (!editJob?.id) {
        const res = await authService.getApolloClient().query({
          query: gql`
            mutation JobsController_newJob($regionId: Int!) {
              JobsController_newJob(regionId: $regionId) {
                id
                status
                region
                createdAt
                updatedAt
                variables
              }
            }
          `,
          variables: { regionId: Number(props?.region?.id) },
        });

        editJob = res.data.JobsController_newJob;
      }
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_updateJob($jobId: Int!, $variables: JobModel_TF_ClusterVariables!) {
            JobsController_updateJob(jobId: $jobId, variables: $variables) {
              id
              status
              region
              createdAt
              updatedAt
              variables
            }
          }
        `,
        variables: {
          jobId: editJob.id || 0,
          variables: values,
        },
      });
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_runJob($jobId: Int!) {
            JobsController_runJob(jobId: $jobId) {
              id
            }
          }
        `,
        variables: { jobId: editJob.id },
      });

      if (props.onSave) {
        props.onSave();
      }
    } catch (e) {
      setInLoading(false);
      console.log(`runJobFunction: ${e}`);
      debugger;
    }
    setInLoading(false);
  };

  return (
    <Form
      data-qa="Clusters/new-cluster-v2/ChangeAWSkeysAndRunJob.tsx"
      labelCol={{ flex: '220px' }}
      labelWrap={true}
      style={{ ...texts, maxWidth: 480 }}
      onFinish={async value => {
        try {
          await createOrUpdateJob(value);
        } catch (e) {
          console.log(e);
        }
      }}
      initialValues={{
        max_capacity: props.editJob?.variables?.max_capacity || 8,
        min_capacity: props.editJob?.variables?.min_capacity || 2,
        desired_capacity: props.editJob?.variables?.desired_capacity || 2,
        instance_types: props.editJob?.variables?.instance_types || 'm4.xlarge',
        cluster_version: props.editJob?.variables?.cluster_version || DEFAULT_CLUSTER_VERSION,
        disk_size: props.editJob?.variables?.disk_size || 80,
        nat_type: natValue,
      }}
    >
      <Title level={5} style={titles}>
        Region configurations
      </Title>

      <Form.Item key={`regionName`} name={`regionName`} label={`AWS region`} initialValue={region.regionName || ''}>
        <AutoComplete
          options={awsRegionNames.map(region => ({ value: region }))}
          filterOption={(input, option) => option?.value.toLowerCase().includes(input.toLowerCase())}
        >
          <Input placeholder="Select AWS region here" />
        </AutoComplete>
      </Form.Item>

      <Form.Item
        key={`TF_AWS_ACCESS_KEY_ID`}
        name={`TF_AWS_ACCESS_KEY_ID`}
        label={`AWS_ACCESS_KEY_ID`}
        initialValue={region.TF_AWS_ACCESS_KEY_ID || ''}
      >
        <Password placeholder="Enter AWS access key ID here" />
      </Form.Item>

      <Form.Item key={`TF_AWS_SECRET_ACCESS_KEY`} name={`TF_AWS_SECRET_ACCESS_KEY`} label={`AWS_SECRET_ACCESS_KEY`}>
        <Password placeholder="Enter AWS secret access key here" />
      </Form.Item>

      <Form.Item name="max_capacity" label="Max capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="min_capacity" label="Min capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="desired_capacity" label="Desired capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="instance_types" label="Instance types" help={`comma separated list`}>
        <Input style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="cluster_version" label="Kubernates version">
        <Select>
          {k8sVersionsList.map(version => {
            return <Select.Option value={version}>{version}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item name="disk_size" label="Disk size">
        <InputNumber style={{ width: `100%` }} suffix={`GB`} />
      </Form.Item>

      <Form.Item name="nat_type" label="Nat">
        <Select>
          <Select.Option value={`use_nat_false`}>No NAT</Select.Option>
          <Select.Option value={`single_nat_gateway`}>Single NAT Gateway</Select.Option>
          <Select.Option value={`one_nat_gateway_per_az`}>One NAT Gateway per availability zone</Select.Option>
        </Select>
      </Form.Item>

      <Button disabled={inLoading} type="primary" htmlType="submit" style={buttonBorder}>
        Save & Continue
      </Button>
    </Form>
  );
};
