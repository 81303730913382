import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApiQuery } from 'utils/common';
import { ProjectServiceList, projectService } from 'services/project.service';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { Button, Checkbox, Skeleton, Tag } from 'antd';

export const ProjectServicesList = (props: { srcProjectId: number; drcProjectId: number; onFinish?: any }) => {
  const history = useHistory();
  const [serviceList, servicesError, servicesLoader] = useApiQuery(
    () => projectService.getProjectServiceList(props.srcProjectId),
    [props.srcProjectId],
  );

  const services: ProjectServiceList[] = serviceList;
  const [selected, setServiceNames] = useState([]);

  useEffect(() => {
    if (services) {
      setServiceNames(services.map(elem => elem.name));
    }
  }, [serviceList]);

  const [importService, createServiceRes] = useAuthedMutationWithNotification(gql`
    mutation ProjectController_createService($projectId: Int!, $serviceName: String!, $srcProjectId: Int) {
      ProjectController_createService(projectId: $projectId, serviceName: $serviceName, srcProjectId: $srcProjectId) {
        status
        error
        serviceName
      }
    }
  `);

  if (servicesLoader) return <Skeleton active={true} loading={true} />;

  const createServices = createServiceRes?.data?.ProjectController_createService;

  const createServicesTags = (
    <Tag color={createServices?.error ? 'red' : 'green'}>
      {createServices?.serviceName} - {createServices?.error || 'Imported'}
    </Tag>
  );

  const importButton = () => {
    const handleImportButton = async () => {
      for (let i = 0; i < selected.length; i++) {
        await importService({
          variables: { projectId: Number(props.drcProjectId), serviceName: String(selected[i]), srcProjectId: Number(props.srcProjectId) },
        });
      }
      if (props.onFinish) {
        props.onFinish();
      } else {
        history.push(`/project/${props.drcProjectId}/settings/services`);
      }
    };
    return (
      <Button type="primary" onClick={handleImportButton}>
        Import selected Services
      </Button>
    );
  };

  const checkSelected = elem => {
    if (selected.indexOf(elem) >= 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckbox = elem => {
    if (selected.indexOf(elem) >= 0) {
      const newSelected = [...selected];
      newSelected.splice(selected.indexOf(elem), 1);
      setServiceNames(newSelected);
    } else {
      const newSelected = [...selected];
      newSelected.push(elem);
      setServiceNames(newSelected);
    }
  };

  return (
    <>
      <br />
      <br />
      {services.map(elem => (
        <div key={elem.name}>
          <Checkbox checked={checkSelected(elem.name)} onChange={() => handleCheckbox(elem.name)}>
            {elem.name}
          </Checkbox>
          <br />
        </div>
      ))}
      <br />
      <br />
      {importButton()}
      {createServices && createServicesTags}
    </>
  );
};
