import { Button, Form, Input, InputNumber, Select, Switch } from 'antd';
import { buttonBorder } from 'utils/styles';
import gql from 'graphql-tag';
import { authService } from 'services/auth.service';
import { iJobModel } from 'shared/deployment';
import { DEFAULT_CLUSTER_VERSION, k8sVersionsList } from 'shared/k8sVersions';

/**
 * The second step of the cluster creation form.
 *
 * AWS keys already passed, now we need to configure the cluster.
 * @param props
 * @returns
 */
export const Tf_AwsEks_JobEditForm = (props: { editJob: iJobModel; regionId: number; onFinish?: any }) => {
  let natValue = `single_nat_gateway`;
  if (props.editJob?.variables?.use_nat === false) {
    natValue = `use_nat_false`;
  } else if (props.editJob?.variables?.use_nat === true) {
    if (props.editJob?.variables?.single_nat_gateway) {
      natValue = `single_nat_gateway`;
    } else if (props.editJob?.variables?.one_nat_gateway_per_az) {
      natValue = `one_nat_gateway_per_az`;
    } else {
      natValue = `use_nat_false`;
    }
  }

  const createOrUpdateJob = async (values: any) => {
    let nat_type = values.nat_type;
    delete values.nat_type;

    // Docs:
    // https://registry.terraform.io/modules/terraform-aws-modules/vpc/aws/latest#nat-gateway-scenarios
    if (nat_type === `use_nat_false`) {
      values.use_nat = false;
      values.single_nat_gateway = false;
      values.one_nat_gateway_per_az = false;
    } else if (nat_type === `single_nat_gateway`) {
      values.use_nat = true;
      values.single_nat_gateway = true;
      values.one_nat_gateway_per_az = false;
    } else if (nat_type === `one_nat_gateway_per_az`) {
      values.use_nat = true;
      values.single_nat_gateway = false;
      values.one_nat_gateway_per_az = true;
    }

    try {
      let editJob = props.editJob;
      if (!editJob?.id) {
        const res = await authService.getApolloClient().query({
          query: gql`
            mutation JobsController_newJob($regionId: Int!) {
              JobsController_newJob(regionId: $regionId) {
                id
                status
                region
                createdAt
                updatedAt
                variables
              }
            }
          `,
          variables: { regionId: Number(props.regionId) },
        });

        editJob = res.data.JobsController_newJob;
      }
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_updateJob($jobId: Int!, $variables: JobModel_TF_ClusterVariables!) {
            JobsController_updateJob(jobId: $jobId, variables: $variables) {
              id
              status
              region
              createdAt
              updatedAt
              variables
            }
          }
        `,
        variables: { jobId: editJob.id || 0, variables: values },
      });
      await authService.getApolloClient().query({
        query: gql`
          mutation JobsController_runJob($jobId: Int!) {
            JobsController_runJob(jobId: $jobId) {
              id
            }
          }
        `,
        variables: { jobId: editJob.id },
      });
      props?.onFinish();
    } catch (e) {
      console.log(`runJobFunction: ${e}`);
      debugger;
    }
  };

  return (
    <Form
      data-qa="Clusters/new-cluster-v2/Tf_AwsEks_JobEditForm.tsx"
      layout="horizontal"
      labelCol={{ flex: '220px' }}
      labelWrap={true}
      style={{ maxWidth: 480 }}
      onFinish={async value => {
        try {
          await createOrUpdateJob(value);
        } catch (e) {
          console.log(e);
        }
      }}
      initialValues={{
        max_capacity: props.editJob?.variables?.max_capacity || 8,
        min_capacity: props.editJob?.variables?.min_capacity || 2,
        desired_capacity: props.editJob?.variables?.desired_capacity || 2,
        instance_types: props.editJob?.variables?.instance_types || 'm4.xlarge',
        cluster_version: props.editJob?.variables?.cluster_version || DEFAULT_CLUSTER_VERSION,
        disk_size: props.editJob?.variables?.disk_size || 80,
        nat_type: natValue,
      }}
    >
      <Form.Item name="max_capacity" label="Max capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="min_capacity" label="Min capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="desired_capacity" label="Desired capacity">
        <InputNumber style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="instance_types" label="Instance types" help={`comma separated list`}>
        <Input style={{ width: `100%` }} />
      </Form.Item>

      <Form.Item name="cluster_version" label="Kubernates version">
        <Select>
          {k8sVersionsList.map(version => {
            return <Select.Option value={version}>{version}</Select.Option>;
          })}
        </Select>
      </Form.Item>

      <Form.Item name="disk_size" label="Disk size">
        <InputNumber style={{ width: `100%` }} suffix={`GB`} />
      </Form.Item>

      <Form.Item name="nat_type" label="Nat">
        <Select>
          <Select.Option value={`use_nat_false`}>No NAT</Select.Option>
          <Select.Option value={`single_nat_gateway`}>Single NAT Gateway</Select.Option>
          <Select.Option value={`one_nat_gateway_per_az`}>One NAT Gateway per availability zone</Select.Option>
        </Select>
      </Form.Item>

      <Button type="primary" htmlType="submit" style={buttonBorder}>
        {/** @todo - Add popconfirm to confirm the creation of the cluster - Add a text note about the cost of the cluster - Call JobsController_newClusterPlan mutation if user agrees to create the cluster */}
        Save & Continue
      </Button>
    </Form>
  );
};
