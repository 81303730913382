import gql from 'graphql-tag';
import { iRegionModel } from 'shared/deployment';
import { regionsService } from 'services/regions.service';
import { Alert, AutoComplete, Button, Form, Select, Skeleton, Space, notification } from 'antd';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { useAuthedQuery } from 'utils/qlAuth';
import { useState } from 'react';

export interface iLoadBalancerProps {
  region: iRegionModel;
}

export const LoadBalancer = (props: iLoadBalancerProps) => {
  const { loading, data } = useAuthedQuery(
    gql`
      query ApiAgentController_findExternalServices($regionId: Int!) {
        ApiAgentController_findExternalServices(regionId: $regionId) {
          status
          message
          services
        }
      }
    `,
    { variables: { regionId: props.region.id } /*, pollInterval: 5000*/ },
  );

  const [loadBalancerURL, setLoadbalancer] = useState(props.region?.loadBalancerURL);
  const [loadBalancerType, setType] = useState(props.region?.loadBalancerType);

  const handleSubmit = async () => {
    const res = await regionsService.updateOwnRegion(props.region.id, {
      loadBalancerURL: loadBalancerURL,
      loadBalancerType: loadBalancerType,
    });
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
  };

  if (loading) {
    return <Skeleton active={true} loading={true} style={{ width: '1000px' }} />;
  }

  const services = data?.ApiAgentController_findExternalServices?.services || [];

  const alertDescription = services.map((item, index) => {
    return <li>{item}</li>;
  });

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {data?.ApiAgentController_findExternalServices?.services?.length > 0 && (
          <Alert message="We found this loadbalancers in your cluster:" description={alertDescription} type="info" showIcon />
        )}
        Load balancer URL
        <AutoComplete
          onChange={value => setLoadbalancer(value)}
          style={{ width: '100%' }}
          placeholder="If you have a load balancer, you can specify it here. It will be used for automated DNS configurations for deployments domains."
          options={services.map((item, index) => ({ value: item }))}
          value={loadBalancerURL}
        />
        Load balancer Type
        <Select style={{ width: '100%' }} onChange={value => setType(value)} value={loadBalancerType}>
          <Select.Option value="Nginx">Nginx </Select.Option>
          <Select.Option value="Traefik">Traefik </Select.Option>
          <Select.Option value="Other">Other </Select.Option>
          <Select.Option value="None">None </Select.Option>
        </Select>
      </Space>
      <BottomButtons>
        <Button type="primary" onClick={handleSubmit}>
          Save
        </Button>
      </BottomButtons>
    </>
  );
};
