import { Button, Form, Input, Switch, notification } from 'antd';
import { projectService } from 'services/project.service';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { buttonWidth } from 'utils/styles';
import { iDeployment, iProjectModel } from 'shared/deployment';
import { DeleteDeployment } from 'components/Deployments/Setting/General/DeleteDeployment';
import { TitleUIRow } from 'layout/TitleUI';
import React from 'react';
import { deploymentService } from '../../../services/deployment.service';

export interface iNamespaceConfigurationProps {
  deployment: iDeployment;
}

export const NamespaceConfiguration = (props: iNamespaceConfigurationProps) => {
  const project: iProjectModel = props.deployment.ProjectModel;

  const [oneDeploymentPerCluster, setOneDeploymentPerCluster] = React.useState<boolean>(project.oneDeploymentPerCluster);

  const init = {
    hardcodedNameSpace: project.hardcodedNameSpace,
    notAllowRemoveNamespace: project.notAllowRemoveNamespace,
    useRawYaml: project.useRawYaml,
    oneDeploymentPerCluster: project.oneDeploymentPerCluster,
    helm_name: props.deployment.releaseName,
  };
  const [form] = Form.useForm();
  const Save = async () => {
    const config = form.getFieldsValue();

    if (!config.oneDeploymentPerCluster) {
      config.hardcodedNameSpace = ``;
    }
    await deploymentService.updateDeployment(177, { releaseName: config.helm_name });
    const res = await projectService.setProjectSettings(project.id, config);
    if (res.error) {
      notification.error({ message: `Error - ${res.error}` });
    } else {
      notification.success({ message: `Ready` });
    }
  };

  return (
    <>
      <TitleUIRow title={`Template advanced settings`} />

      <Form
        form={form}
        onFinish={Save}
        layout="vertical"
        initialValues={init}
        validateTrigger="onSubmit"
        validateMessages={{
          required: '${label} is required!',
        }}
      >
        <Form.Item
          help={
            <>
              Allow only one application of this type in the cluster. Ideal for services intended to run only once or those that necessitate a
              specific predefined namespace
            </>
          }
          name="oneDeploymentPerCluster"
          label={<>Set namespace name instead of auto generated name</>}
        >
          <Switch
            onChange={e => {
              setOneDeploymentPerCluster(e);
            }}
          />
        </Form.Item>

        {oneDeploymentPerCluster && (
          <Form.Item label="Namespace name" help={`Deployment will be run in this namespace instead of autogenerated name`} name="hardcodedNameSpace">
            <Input placeholder="Namespace name" />
          </Form.Item>
        )}

        <Form.Item name="notAllowRemoveNamespace" label={`Prevent removing namespace`}>
          <Switch />
        </Form.Item>
        <Form.Item
          name="useRawYaml"
          label={`Apply yaml code without filtration`}
          help={`Make sense if you want to manage kubernetes objects in different namespaces from one deployment`}
        >
          <Switch />
        </Form.Item>
        <br />
        <Form.Item name="helm_name" label={`Helm release name`}>
          <Input placeholder="Helm release name" />
        </Form.Item>

        <BottomButtons>
          <Button type="primary" htmlType="submit" style={buttonWidth}>
            Save
          </Button>
        </BottomButtons>
      </Form>
    </>
  );
};
