import gql from 'graphql-tag';
import { useEffect, useState } from 'react';
import { useAuthedMutationWithNotification } from 'utils/qlAuth';
import { ShowJobLogsBtn } from './JobLogs';
import { ButtonJobControl } from './ButtonJobControl';
import { iJobModel, iRegionModel } from 'shared/deployment';
import { BottomButtons } from 'components/SharedComponents/BottomButtons/BottomButtons';
import { Button, notification, Popover, Skeleton, Space, Table, Tag } from 'antd';
import { CheckCircleTwoTone, CloseCircleOutlined, CloudUploadOutlined, EditOutlined, IssuesCloseOutlined, SyncOutlined } from '@ant-design/icons';
import { ClusterDeleteButton } from '../ClusterDangerZone';

interface JobListProps {
  region: iRegionModel;
  clusterAreReady: boolean;
  query: any;
  refetch: any;
  // Todo: replace query to makeRefech
  // Todo: Pass jobsList as a new param with correct interface defenition
}

export const JobList = (props: JobListProps) => {
  // const [visible, setVisible] = useState(false);
  // const [editJobModal, setEditJobModal] = useState(null);
  const jobsList: iJobModel[] = props.query?.data?.JobsController_jobsList;

  const [cancelJob, runJobRes] = useAuthedMutationWithNotification(gql`
    mutation JobsController_cancelJob($jobId: Int!) {
      JobsController_cancelJob(jobId: $jobId) {
        id
      }
    }
  `);

  useEffect(() => {
    if (runJobRes.data) props.refetch();
  }, [runJobRes]);

  if (props.query.loading) {
    return <Skeleton active={true} loading={true} style={{ width: '200px' }} />;
  }

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Operation', dataIndex: 'tfMode', key: 'tfMode' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { title: 'Created', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Done', dataIndex: 'finishTime', key: 'finishTime' },
    { title: 'Action', dataIndex: 'action', key: 'action' },
    { title: 'Last error', dataIndex: 'lastError', key: 'lastError' },
  ];

  const getActionsUI = (job: iJobModel) => {
    if ([`render-error`].includes(job['status'])) return null;

    // if (job['status'] == 'pre-init') {
    //   const handleButtonClick = () => {
    //     setEditJobModal(job);
    //     handleOpenModal();
    //   };
    //   return <Button onClick={handleButtonClick}> Edit </Button>;
    // }

    let logsBtn = null;
    if (['applyd', 'error', 'load-logs', `finished`, `finished-error`, `canceled`].includes(job['status'])) logsBtn = <ShowJobLogsBtn job={job} />;
    let cancelBtn = null;
    if ([`pre-run`, 'applyd'].includes(job['status'])) {
      const handleCancelButton = async e => {
        await cancelJob({ variables: { jobId: job.id } });
        notification.success({ message: 'Job canceled' });
      };
      cancelBtn = (
        <Button onClick={handleCancelButton} title="Cancel job">
          <CloseCircleOutlined />
        </Button>
      );
    }

    return (
      <Space>
        {logsBtn} {cancelBtn}
      </Space>
    );
  };

  const generateJobList = (values: iJobModel[]) => {
    const result = [];

    if (!values) return [];
    for (let i = 0; i < values.length; i++) {
      const val: any = { ...values[i] };
      if (val['lastError'] == `Completed`) {
        val['lastError'] = <Tag color="green">{val['lastError']}</Tag>;
      } else if (val['lastError']?.length > 30) {
        val['lastError'] = (
          <Popover
            content={<div style={{ maxWidth: '400px', border: '1px solid #CCCCCC', padding: '5px', borderRadius: '5px' }}> {val['lastError']} </div>}
            title="Full error message"
          >
            <Tag color="error"> {val['lastError'].substring(0, 30)}... </Tag>
          </Popover>
        );
      } else if (val['lastError']?.length > 0) {
        val['lastError'] = <Tag color="error"> {val['lastError']} </Tag>;
      } else {
        val['lastError'] = val['lastError'];
      }

      if (val['createdAt'] > 1) {
        const date = new Date(Number(val['createdAt']));
        const formattedDate = date.toLocaleString();
        val['createdAt'] = formattedDate;
      }

      if (val['finishTime'] > 1) {
        const date = new Date(Number(val['finishTime']));
        const formattedDate = date.toLocaleString();
        val['finishTime'] = formattedDate;
      }

      val['action'] = getActionsUI(values[i]);
      if (val['status'] == 'pre-init') {
        val['status'] = (
          <>
            <EditOutlined /> Pre init
          </>
        );
      }

      if (val['status'] == 'pre-run') {
        val['status'] = (
          <>
            <SyncOutlined spin /> In queue
          </>
        );
      }

      if (val['status'] == 'applyd') {
        val['status'] = (
          <>
            <SyncOutlined spin style={{ color: `#2196F3` }} /> In progres
          </>
        );
      }

      if (val['status'] == 'finished') {
        val['status'] = (
          <>
            <CheckCircleTwoTone twoToneColor={['#1f1f1f', '#52c41a']} /> Finished
          </>
        );
      }

      if (val['status'] == 'canceled') {
        val['status'] = (
          <>
            <CloseCircleOutlined /> Canceled
          </>
        );
      }

      if (val['status'] == 'load-logs') {
        val['status'] = (
          <>
            <CloudUploadOutlined /> Load logs
          </>
        );
      }

      if (['error', `render-error`, `finished-error`].includes(val['status'])) {
        val['status'] = (
          <>
            <IssuesCloseOutlined twoToneColor="#FF9800" style={{ color: `#FF9800` }} /> {val['status']}
          </>
        );
      }
      result.push(val);
    }
    // await setShowJobList(result);
    return result;
  };

  // const handleOpenModal = () => setVisible(true);
  // const handleCloseModal = () => setVisible(false);

  return (
    <>
      {/* <Modal
        open={visible}
        title={`Cluster configurations`}
        width={800}
        onCancel={handleCloseModal}
        cancelButtonProps={display}
        okButtonProps={display}
      >
        <EditJob editJob={editJobModal} closeModal={() => handleCloseModal()} />
      </Modal> */}

      <Table columns={columns} dataSource={generateJobList(jobsList)} />

      <BottomButtons extra={<ClusterDeleteButton region={props.region} />}>
        <ButtonJobControl refetchJobList={props.refetch} showJobList={jobsList} region={props.region} clusterAreReady={props.clusterAreReady} />
      </BottomButtons>
    </>
  );
};
