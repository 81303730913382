import { iDeployment, iStatusWebSsh } from 'shared/deployment';
import { apiQuery, iBaseAjaxAnswer, iUseDpApi, useDpApi } from '../utils/common';
import { useAuthedQuery } from 'utils/qlAuth';
import gql from 'graphql-tag';
import { SpecsRenderResult } from 'shared/yaml';

export const useDeploymentDebugUI_serviceYAML = (
  deploymentsId: number,
  serviceName: string,
  deps: any[] = [],
): iUseDpApi<{ specs: any[]; vars: any }> => {
  return useDpApi(() => deploymentService.debugUI_serviceYAML(deploymentsId, serviceName), deps);
};

export const useGqlAllServicesConfigurationUI = (deploymentId: number) => {
  return useAuthedQuery(
    gql`
      query DeploymentsController_AllServicesConfigurationUI($deploymentId: Int!) {
        DeploymentsController_AllServicesConfigurationUI(deploymentId: $deploymentId) {
          hasUIconfig
          UISchema
          serviceVarConfig
          serviceName
          FormSchema {
            title
            type
            properties
          }
        }
      }
    `,
    { skip: !Number(deploymentId), variables: { deploymentId: Number(deploymentId) } },
  );
};

export const useGqlDeploymentHasWebRoutes = (deploymentId: number) => {
  const dpQueryRoutes = useAuthedQuery(
    gql`
      query DeploymentsController_hasRoutes($deploymentId: Int!) {
        DeploymentsController_hasRoutes(deploymentId: $deploymentId) {
          error
          hasRoutes
        }
      }
    `,
    { skip: !Number(deploymentId), variables: { deploymentId: Number(deploymentId) } },
  );
  return dpQueryRoutes.data?.DeploymentsController_hasRoutes?.hasRoutes;
};

export const useGqlDeploymentHasMonitoring = (deploymentId: number, service?: string) => {
  const dpQueryRoutes = useAuthedQuery(
    gql`
      query DeploymentsController_hasMonitoringSpec($deploymentId: Int!) {
        DeploymentsController_hasMonitoringSpec(deploymentId: $deploymentId) {
          error
          monitoring
          services
        }
      }
    `,
    { skip: !Number(deploymentId), variables: { deploymentId: Number(deploymentId) } },
  );
  if (service) {
    return dpQueryRoutes.data?.DeploymentsController_hasMonitoringSpec?.services?.includes(service);
  }

  return dpQueryRoutes.data?.DeploymentsController_hasMonitoringSpec?.monitoring;
};

export const useGqlDeploymentRoutes = (deploymentId: number) => {
  return useAuthedQuery(
    gql`
      query DeploymentsController_getRoutes($deploymentId: Int!) {
        DeploymentsController_getRoutes(deploymentId: $deploymentId) {
          error
          routes
        }
      }
    `,
    { skip: !Number(deploymentId), variables: { deploymentId: Number(deploymentId) } },
  );
};

export const useGqlDeploymentById = (deploymentId: number) => {
  return useAuthedQuery(
    gql`
      query DeploymentsController_getDeployment($deploymentId: Int!) {
        DeploymentsController_getDeployment(deploymentId: $deploymentId) {
          id
          name
          domain
          projectId
          isEnabled
          subdomain
          isReady
          specsErrors
          specsWarns
          region
          env
          isOnpremise
          ingressAnnotations
          dpCreationType
          dpDeployFlow
          useManualApply
          hasChangesForApply
          needApprove
          lastRevisionId
          services
          version
          tags
          releaseName
          RegionModel {
            id
            name
            title
            cloudProvider
            kubernetesVersion
            autoURL
            isPublic
            natIp
          }
          ProjectModel {
            isPublic
            showVerionsTab
            logo
            tenantId
            gitBranch
            id
            name
            uuid
            title
            description
            gitInitialized
            allowCustomClusterRoles
            allowCustomProvisioner
            showEnvVarsTab
            creationType
            oneDeploymentPerCluster
            hardcodedNameSpace
            notAllowRemoveNamespace
            useRawYaml
            allowAutoApplyLimits
            tags
          }
          VersionModel {
            id
            name
            title
            description
            channel
            build
            images
          }
          NamespaceModel {
            id
            name
            lastSyncDelay
            lastSyncTime
          }
        }
      }
    `,
    { skip: !Number(deploymentId), variables: { deploymentId: Number(deploymentId) } },
  );
};

export const useGqlDeploymentsList = () => {
  return useAuthedQuery(
    gql`
      query DeploymentsController_deployments {
        DeploymentsController_deployments {
          id
          name
          domain
          projectId
          isEnabled
          subdomain
          isReady
          specsErrors
          specsWarns
          region
          env
          isOnpremise
          ingressAnnotations
          dpCreationType
          dpDeployFlow
          useManualApply
          hasChangesForApply
          needApprove
          lastRevisionId
          services
          version
          tags
          releaseName
          RegionModel {
            id
            name
            title
            cloudProvider
            kubernetesVersion
            isPublic
          }
          ProjectModel {
            isPublic
            showVerionsTab
            logo
            tenantId
            gitBranch
            id
            name
            uuid
            title
            description
            gitInitialized
            allowCustomClusterRoles
            allowCustomProvisioner
            showEnvVarsTab
            creationType
            oneDeploymentPerCluster
            hardcodedNameSpace
            notAllowRemoveNamespace
            useRawYaml
            allowAutoApplyLimits
            tags
          }
          VersionModel {
            id
            name
            title
            description
            channel
            build
            images
          }
          NamespaceModel {
            id
            name
            lastSyncDelay
          }
        }
      }
    `,
  );
};
/**
 * @deprecated - use DeploymentsController_deployments
 * @returns
 */
export const useProjectDeployments = (projectId: number, deps: any[] = []): iUseDpApi<iDeployment[]> => {
  return useDpApi(() => apiQuery('GET', `/api/deployments/project/${projectId}`), deps);
};

export const useStatusWebSsh = (deploymentsId: number, deps: any[] = []): iUseDpApi<iStatusWebSsh> => {
  return useDpApi(() => apiQuery('GET', `/api/deployments/${deploymentsId}/statusWebSsh`), deps);
};

class DeploymentService {
  async debugUI_serviceYAML(deploymentsId: number, serviceName: string): Promise<iBaseAjaxAnswer<{ specs: SpecsRenderResult; vars: any }>> {
    return await apiQuery('GET', `/api/deployments/${deploymentsId}/debug-ui/deploymentYAML/${serviceName}`);
  }

  async getProjectDeployments(projectId: number): Promise<iBaseAjaxAnswer<iDeployment[]>> {
    return apiQuery('GET', `/api/deployments/project/${projectId}`);
  }

  async getDeploymentSettingsHistory(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/history`);
  }

  async updateApiKeys(deploymentsId: number, apiKey: any, data: any) {
    return apiQuery('POST', `/api/deployments/${deploymentsId}/api-keys/${apiKey}`, data);
  }

  async addApiKeys(deploymentsId: number, apiKey: any, data: any) {
    return apiQuery('POST', `/api/deployments/${deploymentsId}/api-keys`, data);
  }

  async deleteApiKeys(deploymentsId: number, apiKey: any) {
    return apiQuery('DELETE', `/api/deployments/${deploymentsId}/api-keys/${apiKey}`);
  }

  async getApiKeys(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/api-keys`);
  }

  async updateDeployment(deploymentsId: number, data: any) {
    return apiQuery('POST', `/api/deployments/${deploymentsId}`, data);
  }

  async deleteDeployment(deploymentsId: number) {
    return apiQuery('DELETE', `/api/deployments/${deploymentsId}`);
  }

  async getOnPremiseKeys(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/on-premise/keys`);
  }

  async getServiceLogs(deploymentsId: number, serviceName: string, container: string) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/logs/${serviceName}?container=${encodeURIComponent(container)}`);
  }

  async debugUI_deploymentYAML(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/debug-ui/deploymentYAML`);
  }

  async debugUI_deploymentSyncVars(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/debug-ui/deploymentSyncVars`);
  }

  async debugUI_deploymentRoutes(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/debug-ui/deploymentRoutes`);
  }

  async podsOnNodes(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/pods-on-nodes`);
  }

  async getInfoUpdateDeployment(versionId: any) {
    return apiQuery('GET', `/api/deployments/${versionId}/infoupdate`);
  }
  async startWebSsh(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/startWebSsh`);
  }
  async stopWebSsh(deploymentsId: number) {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/stopWebSsh`);
  }
  async statusWebSsh(deploymentsId: number): Promise<iBaseAjaxAnswer<iStatusWebSsh>> {
    return apiQuery('GET', `/api/deployments/${deploymentsId}/statusWebSsh`);
  }
}

const deploymentService = new DeploymentService();
export { deploymentService, DeploymentService };
