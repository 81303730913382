import { useState } from 'react';
import { projectService } from 'services/project.service';
import { Button, Input, Modal, Popconfirm, Spin, Typography, notification, Select, Form, Space } from 'antd';
import { GitlabOutlined } from '@ant-design/icons';
import { buttonWidth, gitButtonModalStyle } from 'utils/styles';
import { UnifieQuestionCircleIcon } from 'components/CustomIcons/CustomIcons';

interface iProjectGitBtns {
  projectId: number;
  disabled?: boolean;
  onClick?: () => void;
}

interface iProjectGitBtn_push extends iProjectGitBtns {
  dstBranch: string;
}

const { Text } = Typography;

async function showModalWithGitCommandResult(title: string, resPromis: Promise<any>, onClose?: (isOk: boolean) => void) {
  Modal.destroyAll();
  Modal.success({
    title: 'Loading...',
    content: <Spin size="large" />,
    icon: <GitlabOutlined />,
    closable: false,
    onOk: () => resPromis,
  });

  const linksError = {
    conflict: 'https://www.unifie.cloud/doc/docs/Configuration-as-code/Git-Error/Git-Conflict/',
    repNotFound: 'https://www.unifie.cloud/doc/docs/Configuration-as-code/Git-Error/Repository-Not-Found/',
    'could-not-resolve-host': 'https://www.unifie.cloud/doc/docs/Configuration-as-code/Git-Error/Repository-Not-Found/',
    auth: 'https://www.unifie.cloud/doc/docs/Configuration-as-code/Git-Error/Authorization-Error/',
    branch: 'https://www.unifie.cloud/doc/docs/Configuration-as-code/Git-Error/Branch-Error/',
  };

  const res = await resPromis;
  let modalContent = null;
  let modalTitle = null;
  let isGitError = false;
  modalContent = (
    <pre style={gitButtonModalStyle}>
      {res?.data?.results?.step1?.stdout}
      {res?.data?.results?.step1?.stderr}
      {res?.data?.results?.step2?.stdout}
      {res?.data?.results?.step2?.stderr}
    </pre>
  );
  if (res?.data) {
    Modal.destroyAll();
    if (res.data?.gitError) {
      isGitError = true;
    }
    if (isGitError) {
      Modal.error({
        icon: <GitlabOutlined />,
        title: title,
        width: '800px',
        content: (
          <Space direction="vertical">
            <pre style={gitButtonModalStyle}>
              <div>{res.data?.message}</div>
            </pre>
            {linksError[res.data?.type] && (
              <a href={linksError[res.data?.type]} target="_blank">
                <UnifieQuestionCircleIcon /> How to solve this error?
              </a>
            )}
          </Space>
        ),
      });
    } else {
      Modal.success({
        icon: <GitlabOutlined />,
        title: title,
        width: '800px',
        content: modalContent,
      });
    }

    return res;
  }
}
export const projectGitPull = async (projectId: number, onClose?: (isOk: boolean) => void) => {
  await showModalWithGitCommandResult(`Pull`, projectService.gitPullProject(projectId), onClose);
};

const cloneBtn = async (projectId: number) => await showModalWithGitCommandResult(`Clone`, projectService.gitCloneProject(projectId));

const pushBtn = async (dstBranch: string, projectId: number) =>
  await showModalWithGitCommandResult(`Push`, projectService.gitPushProject(projectId, dstBranch));

export const ProjectGitBtn_push = (props: iProjectGitBtn_push) => {
  const [dstBranch, setDstBranch] = useState(null);
  const resdstBranch = dstBranch || props.dstBranch;

  const handlePushClick = async () => {
    if (resdstBranch && resdstBranch != 'null') {
      await pushBtn(resdstBranch, props.projectId);
    } else {
      notification.error({
        message: 'The branch must be specified and not null.',
      });
    }
    props.onClick ? props.onClick() : null;
  };

  const popDescription = (
    <>
      <Text> Are you sure you want to push changes to git? </Text>
      <Text> Target Branch </Text>
      <Input placeholder="Branch name" value={resdstBranch} onChange={e => setDstBranch(e.target.value)} />
    </>
  );

  if (resdstBranch) {
    return (
      <Popconfirm
        title="Push changes to git?"
        description={popDescription}
        okText={`Push to ${resdstBranch}`}
        cancelText="No"
        key={`push`}
        onConfirm={handlePushClick}
      >
        <Button type="primary" disabled={props.disabled}>
          Git Push
        </Button>
      </Popconfirm>
    );
  } else {
    return (
      <Button
        type="primary"
        disabled={props.disabled}
        onClick={() => {
          notification.error({
            message: 'The branch must be specified and not null.',
          });
        }}
      >
        Git Push
      </Button>
    );
  }
};

export const ProjectGitBtn_Pull = (props: iProjectGitBtns) => {
  const handlePullClick = async () => {
    await projectGitPull(props.projectId);
    if (props.onClick) {
      props.onClick();
    }
  };
  const pullFile = () => (
    <Button type="primary" disabled={props.disabled} key={`pull`} onClick={handlePullClick}>
      Git Pull
    </Button>
  );
  return pullFile();
};

export const ProjectGitBtn_Clone = (props: iProjectGitBtns) => {
  const handleCloneClick = async () => {
    await cloneBtn(props.projectId);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <Popconfirm
      key={`clone`}
      title="Remove all local files and Git Clone?"
      description={
        <>
          You will lose all local changes.
          <br />
          Are you sure you want to clone the project?
        </>
      }
      okText={`Clean & Clone`}
      cancelText="No"
      onConfirm={handleCloneClick}
    >
      <Button color="danger" variant="solid" disabled={props.disabled} key={`clone`}>
        Clean & Clone
      </Button>
    </Popconfirm>
  );
};
