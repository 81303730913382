import { ReactElement, useEffect, useState } from 'react';
import { Skeleton, Tabs, Switch, Menu, MenuProps } from 'antd';
import { useHistory } from 'react-router-dom';
import { iDeployment } from 'shared/deployment';
import { ProjectFiles } from 'components/Projects/FileEditor/ProjectFiles';
import { ProjectContainerStorage } from 'components/Projects/ProjectContainerStorage/ProjectContainerStorage';
import { ProjectGitSettings } from 'components/Projects/Settings/Git/ProjectGitSettings';
import { ProjectDocs } from 'components/Projects/Settings/ProjectDocs';
import { ProjectVersions } from 'components/Projects/Settings/ProjectVersion';
import { ShareProject } from 'components/Projects/Settings/ShareProject';
import { isInAdvancedMode, setAdvancedMode } from 'utils/util';
import { userHasPermission, UserPermissions } from 'shared/UserRolesPermission';
import { useUser } from 'utils/common';
import { DeleteTab } from 'components/Deployments/Setting/General/settings/DeleteTab';
import { OverviewForNotReadyDeployment } from 'components/Deployments/New/OverviewForNotReadyDeployment';
import DeploymentEnvVariablesForm from 'components/Deployments/Setting/EnvironmentVariable/EnvironmentVariable';
import DeploymentUpdateVersionForm from 'components/Deployments/Setting/General/DeploymentUpdateVersion';
import { IngressAnnotationForm } from 'components/Deployments/Setting/IngressAnnotationForm/IngressAnnotationForm';
import { Storage_S3 } from 'components/Deployments/Setting/General/Storage';
import { NamespaceConfiguration } from 'components/Projects/Settings/NamespaceConfiguration';
import { BranchesOutlined } from '@ant-design/icons';
import DeploymentGeneralSettingsForm from 'components/Deployments/Setting/General/General';
import { StarFilled } from '@ant-design/icons/lib';

interface iConfigurationSettings {
  deployment: iDeployment;
  settingsTab: string;
}

const { TabPane } = Tabs;

export const ConfigurationSettings = (props: iConfigurationSettings): ReactElement => {
  const history = useHistory();
  const [isAdvancedMode, setIsAdvancedMode] = useState(isInAdvancedMode(`dp`, props.deployment.id));
  console.log(isAdvancedMode);
  const user = useUser();
  const [tabName, setTabName] = useState(props.settingsTab);

  const advancedTabs = [`git`, `docs`, `share`, `ci-cd`, `project-files`, `namespace`, `storage`, `cronjobs`, `ingress`];
  const url: string = window.location.hash;
  const getActiveTabs = (url: string) => {
    const parts: string[] = url.split('/');
    const lastValue: string = parts[parts.length - 1];
    return lastValue;
  };
  const [current, setCurrent] = useState(getActiveTabs(url));

  const project = props.deployment.ProjectModel;
  const deployment = props.deployment;

  let defaultTab = tabName;
  const Permit_ProjectsMange = userHasPermission(user, UserPermissions.ProjectsMange);

  if (!defaultTab) {
    if (deployment.isReady === false) {
      defaultTab = 'general';
    } else {
      defaultTab = 'danger-zone';
    }
  }
  if (advancedTabs.includes(current) && isAdvancedMode == false) {
    setAdvancedMode(`dp`, props.deployment.id, true);
  }

  if (!tabName) {
    debugger;
    history.push(`/app/${deployment.id}/configuration/${defaultTab}`);
    return null;
  }

  if (!deployment || !user) {
    return <Skeleton active={true} loading={true} />;
  }

  const onClick: MenuProps['onClick'] = e => {
    setCurrent(e.key);
    history.push(`/app/${props.deployment.id}/configuration/settings/${e.key}`);
  };

  const gitTabs = [
    deployment.isReady === true ? { key: 'danger-zone', label: 'Danger zone', component: <DeleteTab id={deployment.id} /> } : null,
    deployment.isReady === false
      ? {
          permission: Permit_ProjectsMange,
          key: 'general',
          label: 'General',
          component: <OverviewForNotReadyDeployment deploymentId={deployment.id} />,
        }
      : {
          permission: Permit_ProjectsMange,
          key: 'general',
          label: 'General',
          component: <DeploymentGeneralSettingsForm deploymentId={deployment.id} />,
        },
    {
      permission: Permit_ProjectsMange,
      key: 'git',
      label: 'Git',
    },
    { permission: Permit_ProjectsMange, key: 'docs', label: 'Docs', component: <ProjectDocs deployment={deployment} /> },
    deployment.isReady === false
      ? { permission: Permit_ProjectsMange, key: 'share', label: 'Share', component: <ShareProject project={project} /> }
      : null,
    { permission: Permit_ProjectsMange, key: 'project-files', label: 'Files', component: <ProjectFiles deployment={deployment} /> },
    {
      permission: Permit_ProjectsMange,
      key: 'pull-secrets',
      label: 'Pull secrets',
      component: <ProjectContainerStorage deployment={deployment} />,
    },
    { permission: Permit_ProjectsMange, key: 'ci-cd', label: 'CI/CD', component: <ProjectVersions project={project} /> },
    {
      permission: Permit_ProjectsMange,

      key: 'namespace',
      label: 'Namespace',
      component: <NamespaceConfiguration deployment={deployment} />,
    },

    {
      permission: Permit_ProjectsMange,

      key: 'env',
      label: 'Environment variables',
      component: <DeploymentEnvVariablesForm deployment={deployment} />,
      condition: true, //deployment.ProjectModel?.showEnvVarsTab !==  false
    },
    {
      permission: Permit_ProjectsMange,

      key: 'version',
      label: 'Version',
      component: <DeploymentUpdateVersionForm deployment={deployment} />,
      condition: deployment.ProjectModel?.showVerionsTab !== false,
    },

    // Only for nanoheal project
    {
      permission: Permit_ProjectsMange,

      key: 'storage',
      label: 'Storage',
      component: <Storage_S3 deployment={deployment} />,
      condition: deployment.ProjectModel.name === 'nanoheal',
    },
    // Only for nanoheal project
    //   // Only for nanoheal project
    {
      permission: Permit_ProjectsMange,

      key: 'ingress',
      label: 'Ingress',
      component: <IngressAnnotationForm deployment={deployment} />,
      // condition: deployment.ProjectModel.name === 'nanoheal',
    },
  ];

  const gitTabsFiltered = gitTabs
    .filter(Boolean)
    .filter(v => v.permission !== false && v.condition !== false)
    .filter(v => advancedTabs.includes(v.key) === isAdvancedMode || advancedTabs.includes(v.key) === false);

  let result = null;
  const realTab = tabName || defaultTab;
  if (deployment.isReady === false && current == 'general') {
    result = <OverviewForNotReadyDeployment deploymentId={deployment.id} />;
  } else {
    result = <DeploymentGeneralSettingsForm deploymentId={deployment.id} />;
  }
  if (current == 'danger-zone') {
    result = <DeleteTab id={deployment.id} />;
  }
  if (current == 'git') {
    result = <ProjectGitSettings deployment={deployment} />;
  }
  if (current == 'docs') {
    result = <ProjectDocs deployment={deployment} />;
  }
  if (current == 'share') {
    result = <ShareProject project={project} />;
  }
  if (current == 'project-files') {
    result = <ProjectFiles deployment={deployment} />;
  }
  if (current == 'pull-secrets') {
    result = <ProjectContainerStorage deployment={deployment} />;
  }
  if (current == 'ci-cd') {
    result = <ProjectVersions project={project} />;
  }
  if (current == 'namespace') {
    result = <NamespaceConfiguration deployment={deployment} />;
  }
  if (current == 'env') {
    result = <DeploymentEnvVariablesForm deployment={deployment} />;
  }
  if (current == 'version') {
    result = <DeploymentUpdateVersionForm deployment={deployment} />;
  }
  if (current == 'storage') {
    result = <Storage_S3 deployment={deployment} />;
  }
  if (current == 'ingress') {
    result = <IngressAnnotationForm deployment={deployment} />;
  }

  return (
    <>
      <Menu
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={gitTabsFiltered}
        style={{ width: '90%', float: 'left', backgroundColor: 'initial' }}
      />
      <Switch
        style={{ float: 'right' }}
        checkedChildren="Advanced Mode"
        unCheckedChildren="Basic mode"
        onClick={val => {
          setAdvancedMode(`dp`, props.deployment.id, val);
          if (val) {
            setIsAdvancedMode(true);
          } else {
            if (advancedTabs.includes(realTab)) {
              history.push(`/app/${deployment.id}/configuration/settings/general`);
              setIsAdvancedMode(false);
              setTabName(`general`);
            } else {
              setIsAdvancedMode(false);
            }
          }
        }}
        checked={isAdvancedMode}
      />
      <div style={{ marginTop: '65px' }}>{result}</div>
    </>
  );
};
